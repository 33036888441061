import { NgClass, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { NourishProductHeaderComponent } from '../../nourish-product-header/nourish-product-header.component';
import { nourishProductCtaClicked } from '../../store/nourish.actions';
import { selectSelectedNourishProductTile } from '../../store/nourish.selectors';

@Component({
    selector: 'app-nourish-product',
    templateUrl: './nourish-product.page.html',
    styleUrl: './nourish-product.page.scss',
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        IonContent,
        IonHeader,
        IonToolbar,
        IonButton,
        IonFooter,
        TextReplacementPipe,
        NourishProductHeaderComponent,
    ],
})
export class NourishProductPage {
    private readonly store = inject(Store);

    public product = this.store.selectSignal(selectSelectedNourishProductTile);

    protected readonly Color = Color;

    public onCtaClick(): void {
        if (!this.product()) {
            return;
        }

        this.store.dispatch(
            nourishProductCtaClicked({
                id: this.product()!.id,
                url: this.product()!.callToAction.link,
            }),
        );
    }
}
