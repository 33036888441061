import { NgStyle } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { IonButton } from '@ionic/angular/standalone';
import { RadioWithLogicItem, RadioWithLogicResult } from './radio-with-logic.model';

@Component({
    standalone: true,
    selector: 'app-radio-with-logic',
    templateUrl: 'radio-with-logic.component.html',
    styleUrl: 'radio-with-logic.component.scss',
    imports: [NgStyle, IonButton],
})
export class RadioWithLogicComponent {
    public buttons = input.required<RadioWithLogicItem[]>();
    public isDisabled = input(false);

    public changeEvent = output<RadioWithLogicResult>();

    public selected!: RadioWithLogicItem | undefined;

    public onButtonClick(radio: RadioWithLogicItem): void {
        if (this.isDisabled()) {
            return;
        }

        this.selected = !this.selected ? radio : undefined;

        this.changeEvent.emit({
            complete: !!this.selected,
            selected: this.selected,
        });
    }
}
