import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { dismissibleInfoFeature, dismissInfo, isItemDismissed } from '@frontend/data-access/dismissible-info';
import { openUrlWindow, routeTo } from '@frontend/data-access/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { routeToAiChat } from '../../chat/chat.actions';
import { tabClicked } from '../../tabs/store/tab.actions';
import { TabRoute_NOURISH } from '../../tabs/tabs.constants';
import { NOURISH_TAB_INDICATOR_ID, NourishTileId } from '../constants/nourish.constants';
import { closeNourishModal, openNourishModal } from '../modals/store/nourish-modal.actions';
import {
    nourishCalculateFoodButtonClicked,
    nourishProductCtaClicked,
    nourishProductTileClicked,
    nourishSuggestedAiChatQuestionClicked,
    nourishTileClicked,
} from './nourish.actions';

@Injectable()
export class NourishEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    routeToNourishFoodCalculator$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishCalculateFoodButtonClicked),
            map(() => {
                return routeTo({ commands: ['main', 'nourish', 'food-portion-calculator'] });
            }),
        );
    });

    routeToNourish$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            map(() => {
                return routeTo({ commands: ['main', 'nourish'] });
            }),
        );
    });

    routeToProductPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            map(({ id }) => {
                return routeTo({
                    commands: ['main', 'nourish', 'product', id],
                });
            }),
        );
    });

    routeToAiChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishSuggestedAiChatQuestionClicked),
            map(({ optionId }) => {
                return routeToAiChat({ message: optionId });
            }),
        );
    });

    closeNourishModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            filter(({ correlationId }) => correlationId === 'modal'),
            map(() => closeNourishModal()),
        );
    });

    dismissNourishTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishCalculateFoodButtonClicked),
            concatLatestFrom(() => this.store.select(dismissibleInfoFeature.selectDismissedInfoItems)),
            filter(([, dismissedInfo]) => !isItemDismissed(dismissedInfo, NourishTileId.CALCULATE_FOOD)),
            map(() => {
                return dismissInfo({
                    id: NourishTileId.CALCULATE_FOOD,
                    dontShowAgain: true,
                });
            }),
        );
    });

    dismissNourishIndicator$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(tabClicked, nourishTileClicked),
            concatLatestFrom(() => this.store.select(dismissibleInfoFeature.selectDismissedInfoItems)),
            filter(
                ([{ id }, dismissedInfo]) =>
                    (id === TabRoute_NOURISH || id === NourishTileId.CALCULATE_FOOD) &&
                    !isItemDismissed(dismissedInfo, NOURISH_TAB_INDICATOR_ID),
            ),
            map(() => {
                return dismissInfo({
                    id: NOURISH_TAB_INDICATOR_ID,
                    dontShowAgain: true,
                });
            }),
        );
    });

    triggerOpenNourishModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            filter(({ id }) => id !== NourishTileId.CALCULATE_FOOD),
            map(({ id }) => {
                return openNourishModal({ id });
            }),
        );
    });

    openExternalUrl$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductCtaClicked),
            map(({ url }) => {
                return openUrlWindow({
                    url,
                    openInNewWindow: false,
                });
            }),
        );
    });

    trackNourishTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            map(({ id }) => {
                return trackEvent({
                    eventName: '[Nourish] Tile Clicked',
                    eventProperties: {
                        tileId: id,
                    },
                });
            }),
        );
    });

    trackNourishProductTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            map(({ id, correlationId }) => {
                return trackEvent({
                    eventName: '[Nourish] Product Tile Clicked',
                    eventProperties: {
                        productId: id,
                        origin: correlationId,
                    },
                });
            }),
        );
    });

    trackNourishProductCtaClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductCtaClicked),
            map(({ id, url }) => {
                return trackEvent({
                    eventName: '[Nourish] Product CTA Clicked',
                    eventProperties: {
                        productId: id,
                        url,
                    },
                });
            }),
        );
    });

    trackSuggestedAiChatQuestionClick$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishSuggestedAiChatQuestionClicked),
            map(({ option, card }) => {
                return trackEvent({
                    eventName: '[Nourish] Ai Chat Suggested Question Clicked',
                    eventProperties: { option, card },
                });
            }),
        );
    });
}
