<div class="lincoln-survey-modal">
    <button
        class="lincoln-survey-modal__close-button zz-button xl"
        appTrackClick
        identifier="lincoln-survey-modal-close-btn"
        (click)="closeModal()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <section class="lincoln-survey-modal__title-container ignore-global-color">
        <img
            class="lincoln-survey-modal__lincoln-logo"
            [src]="'/assets/images/third-party/lincoln-university-logo.png'"
            alt="The Lincoln University logo"
        />
        <h2>{{ 'You and [NAME] are invited!' | textReplacement }}</h2>
        <p class="lincoln-survey-modal__subtitle">Take part in new research into puppy happiness.</p>
    </section>
    <section class="lincoln-survey-modal__button-container">
        <ion-button
            appTrackClick
            identifier="lincoln-survey-open-link-btn"
            [color]="Color.Max"
            [href]="'https://unilincolnlifescienc.qualtrics.com/jfe/form/SV_3PrkgNEWV7k7qyG'"
        >
            Participate now
        </ion-button>
        <button
            class="lincoln-survey-modal__no-thanks-button zz-button"
            appTrackClick
            identifier="lincoln-survey-modal-no-thanks-btn"
            (click)="closeModal()"
        >
            No, thanks
        </button>
    </section>
</div>
