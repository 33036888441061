import { RichTextContent } from 'contentful';

export interface InternalDailyBiteDto {
    id: number;
    title: string;
    imageUrl: string;
    rank: number;
    author: string;
    tags: string[];
    richContent?: RichTextContent;
    dailyBiteAuthor:
        | {
              authorName: string;
              authorImageUrl: string;
              authorBio: RichTextContent;
              authorCertifications: string[];
              authorOrganisation: string;
          }
        | undefined;
}
