import { Component, inject } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ModalService } from '@frontend/utility/modal';
import { IonButton } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';

@Component({
    standalone: true,
    selector: 'app-lincoln-survey-modal',
    templateUrl: './lincoln-survey-modal.html',
    styleUrl: './lincoln-survey-modal.scss',
    imports: [AnalyticsTrackClickDirective, IonButton, TextReplacementPipe],
})
export class LincolnSurveyModalComponent {
    public readonly modalService = inject(ModalService);

    protected readonly Color = Color;

    public closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
