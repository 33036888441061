import { coursesFeature, seasonalTopicsFeature } from '@frontend/data-access/contentful';
import { selectIsNourish, selectIsStartCourseAb } from '@frontend/data-access/user/config-cat';
import { householdFeature, selectAgeInWeeks, selectHasArrived } from '@frontend/data-access/user/household';
import { courseProgressFeature } from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';
import { OptionalCourseId } from '@shared/constants';
import { CourseType } from '@shared/content-api-interface';
import { CourseProgressType } from '@shared/user-domain';
import { Color } from '@shared/utils/typescript';
import { foodCalculatorFeature } from '../food-calculator/data-access/store/food-calculator.reducer';
import { NOURISH_CALCULATE_FOOD_TASK_TILE } from '../nourish/constants/nourish.constants';
import { selectCoursesWithProgressInfo } from '../store/course/course-with-progress.selectors';
import { selectContentUnlocked, selectIsBasicPlan } from '../store/payment/store/payment.selectors';
import {
    TileType,
    TodayCourse,
    TodayGenericCardSquare,
    TodayProgressCourse,
    TodayRecommendedTiles,
    TodaySeasonalTopic,
} from './today.model';

export const selectTodayPageSupportTiles = createSelector(
    selectContentUnlocked,
    selectIsBasicPlan,
    (contentUnlocked, isBasicPlan): TodayGenericCardSquare[] => {
        return [
            {
                imageUrl: '/assets/images/trainers/coach-petrina-red-circle.png',
                title: 'Training coach',
                description: 'Replies within 1 hour',
                backgroundColor: Color.Harley,
                cardName: 'puppy-coach',
                contentUnlocked: contentUnlocked && !isBasicPlan,
                isBasicPlan,
            },
        ];
    },
);

export const selectTodayPageSeasonalTopics = createSelector(
    seasonalTopicsFeature.selectSeasonalTopics,
    (seasonalTopics): TodaySeasonalTopic[] => {
        return seasonalTopics.map((seasonalTopic) => ({
            ...seasonalTopic,
            tileType: TileType.SEASONAL_TOPIC,
        }));
    },
);

export const selectTodayPageCourses = createSelector(coursesFeature.selectCourses, (courses): TodayCourse[] => {
    return courses.map((course) => ({
        ...course,
        tileType: TileType.COURSE,
    }));
});

export const selectTodayPageProgressCourses = createSelector(
    selectCoursesWithProgressInfo,
    (courses): TodayProgressCourse[] => {
        return courses.map((course) => ({
            ...course,
            tileType: TileType.COURSE,
        }));
    },
);

export const selectTodayPageAbCourses = createSelector(
    selectTodayPageCourses,
    selectTodayPageProgressCourses,
    selectIsStartCourseAb,
    (courses, coursesWithProgress, isStartCourseAb): TodayCourse[] => {
        return isStartCourseAb ? [...coursesWithProgress] : [...courses];
    },
);

export const selectShouldShowNourishTile = createSelector(
    selectIsNourish,
    foodCalculatorFeature.selectFoodPortionAmount,
    (isNourish, foodPortion): boolean => {
        return isNourish && !foodPortion;
    },
);

export const selectTodayPageRecommendedTiles = createSelector(
    selectTodayPageAbCourses,
    selectHasArrived,
    selectAgeInWeeks,
    householdFeature.selectIsRescuePup,
    courseProgressFeature.selectProgress,
    selectTodayPageSeasonalTopics,
    selectShouldShowNourishTile,
    (
        courses,
        hasArrived,
        ageInWeeks,
        isRescue,
        courseProgress,
        seasonalTopics,
        shouldShowNourishTile,
    ): TodayRecommendedTiles => {
        let result: TodayRecommendedTiles = [];

        if (!courses.length || hasArrived === undefined || ageInWeeks === undefined || isRescue === undefined) {
            return result;
        }

        // Pre-pup
        if (!hasArrived) {
            const prePupCourse = courses.filter((course) => course.type === CourseType.PRE_PUP);
            result.push(...prePupCourse);
        }

        // Foundational
        const foundationalCourses = courses.filter((course) => course.type === CourseType.FOUNDATIONAL);
        result.push(...foundationalCourses);

        if (!hasArrived) {
            return result;
        }

        const separationFoundationsCourse = courses.filter(
            (course) => course.id === OptionalCourseId.SEPARATION_FOUNDATIONS,
        );

        // Separation Foundations for 6 months+
        if (ageInWeeks >= 26) {
            result.push(...separationFoundationsCourse);
        }

        // Pup Master
        if (ageInWeeks >= 0 && ageInWeeks <= 25) {
            const pupMasterCourse = courses.filter((course) => course.id === OptionalCourseId.PUP_MASTER);
            result.push(...pupMasterCourse);
        }

        // Terrible Teens
        if (ageInWeeks >= 12 && ageInWeeks <= 51 && !isRescue) {
            const terribleTeensCourse = courses.filter((course) => course.id === OptionalCourseId.TERRIBLE_TEENS);
            result.push(...terribleTeensCourse);
        }

        // Trick Starter
        if (ageInWeeks >= 26) {
            const trickStarterCourse = courses.filter((course) => course.id === OptionalCourseId.TRICK_STARTER);
            result.push(...trickStarterCourse);
        }

        // Separation Foundations for 12 weeks - 6 months
        if (ageInWeeks >= 12 && ageInWeeks < 26) {
            result.push(...separationFoundationsCourse);
        }

        if (courseProgress && courseProgress.length) {
            courseProgress.forEach((progress) => {
                if (progress.progressType === CourseProgressType.COMPLETED) {
                    result = result.filter((course) => course.id !== progress.courseId);
                }
            });
        }

        if (shouldShowNourishTile && ageInWeeks >= 7) {
            result.splice(1, 0, { ...NOURISH_CALCULATE_FOOD_TASK_TILE, tileType: TileType.NOURISH });
        }

        if (seasonalTopics && seasonalTopics.length) {
            result.splice(1, 0, ...seasonalTopics);
        }

        return result;
    },
);
