<ion-content class="how-to-weigh-your-dog-modal" [color]="Color.Luna">
    <button
        class="how-to-weigh-your-dog-modal__close-button xl"
        appTrackClick
        identifier="nourish-how-to-weigh-your-dog-modal-close-btn"
        data-test="nourish-how-to-weigh-your-dog-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>

    <article class="how-to-weigh-your-dog-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2>How to weigh your dog?</h2>

        <img
            class="how-to-weigh-your-dog-modal__illustration"
            src="/assets/images/bailey/bailey-on-scales.svg"
            alt="Bailey sits on weighing scales"
        />

        <p class="no-margin">
            Keeping track of your dog’s weight is essential for their health, but getting it right can sometimes be
            tricky.
        </p>

        <p class="how-to-weigh-your-dog-modal__highlighted-text-paragraph no-margin">
            @if (ageInWeeks() !== undefined && ageInWeeks()! < 52) {
                You should weigh {{ dogName() }} every 2 weeks to monitor steady growth
            } @else {
                You should weigh {{ dogName() }} about every month to monitor healthy weight
            }
        </p>

        <h3 class="no-margin">Using bathroom scale</h3>
        <ol class="how-to-weigh-your-dog-modal__list no-margin" [ngClass]="Color.AppOutline">
            <li class="how-to-weigh-your-dog-modal__list-item">
                <h3 class="no-margin">
                    <span class="how-to-weigh-your-dog-modal__list-number">①</span> Step on a scale with dog
                </h3>
                <p class="no-margin">Step on the scale holding your dog and note the combined weight</p>
            </li>

            <hr class="how-to-weigh-your-dog-modal__list-break" />

            <li class="how-to-weigh-your-dog-modal__list-item">
                <h3 class="no-margin">
                    <span class="how-to-weigh-your-dog-modal__list-number">②</span> Step on a scale without a dog
                </h3>
                <p class="no-margin">Weigh yourself alone</p>
            </li>

            <hr class="how-to-weigh-your-dog-modal__list-break" />

            <li class="how-to-weigh-your-dog-modal__list-item">
                <h3 class="no-margin"><span class="how-to-weigh-your-dog-modal__list-number">③</span> Calculate</h3>
                <p class="no-margin">Subtract your weight from the combined weight to get your dog’s weight</p>
            </li>
        </ol>

        <section class="how-to-weigh-your-dog-modal__highlighted-text">
            (<i class="how-to-weigh-your-dog-modal__icon fa-solid fa-dog"></i>
            <i class="how-to-weigh-your-dog-modal__icon fa-solid fa-plus"></i>
            <i class="how-to-weigh-your-dog-modal__icon fa-solid fa-person"></i>)
            <i class="how-to-weigh-your-dog-modal__icon fa-solid fa-minus"></i>
            <i class="how-to-weigh-your-dog-modal__icon fa-solid fa-person"></i>
            <i class="how-to-weigh-your-dog-modal__icon fa-solid fa-equals"></i>
            <span>Your pup’s weight</span>
        </section>

        <h3 class="no-margin">Using a pet scale (if available)</h3>
        <ol class="how-to-weigh-your-dog-modal__list no-margin" [ngClass]="Color.AppOutline">
            <li class="how-to-weigh-your-dog-modal__list-item">
                <h3 class="no-margin">
                    <span class="how-to-weigh-your-dog-modal__list-number">①</span> Put dog on scales
                </h3>
                <p class="no-margin">Use a platform scale or floor scale if available</p>
            </li>

            <hr class="how-to-weigh-your-dog-modal__list-break" />

            <li class="how-to-weigh-your-dog-modal__list-item">
                <h3 class="no-margin">⚠️ For restless dogs</h3>
                <p class="no-margin">Weigh with treats or a calm command to keep them still</p>
            </li>
        </ol>

        <h3 class="no-margin">Calculate food portions</h3>
        <p class="no-margin">
            Frequent checks help you adjust food portions as your puppy grows to prevent overfeeding or underfeeding.
        </p>
        <p class="no-margin">Use our food calculator to calculate how much you should be feeding {{ dogName() }}.</p>

        <div class="how-to-weigh-your-dog-modal__calculator-link">
            <ion-button
                class="how-to-weigh-your-dog-modal__calculate-button s"
                appTrackClick
                identifier="nourish-how-to-weigh-your-dog-modal-calculate-btn"
                expand="block"
                data-test="nourish-how-to-weigh-your-dog-modal-calculate-btn"
                [color]="Color.Max"
                (click)="goToFoodCalculator()"
            >
                <i class="how-to-weigh-your-dog-modal__calculate-button-icon fa-solid fa-plus"></i>
                Calculate food portion
            </ion-button>
        </div>

        <section class="how-to-weigh-your-dog-modal__highlighted-text-paragraph">
            <b>When to see the vet?</b>
            <p class="no-margin">Sudden weight changes (gain or loss) may indicate health issues.</p>
        </section>
    </article>
</ion-content>
