import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { householdFeature, selectAgeInWeeks } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';

@Component({
    standalone: true,
    selector: 'app-how-to-weigh-your-dog-modal',
    templateUrl: './how-to-weigh-your-dog-modal.component.html',
    styleUrl: './how-to-weigh-your-dog-modal.component.scss',
    imports: [AnalyticsTrackClickDirective, IonButton, IonContent, NgClass],
})
export class HowToWeighYourDogModalComponent extends NourishModalBaseComponent {
    public dogName = this.store.selectSignal(householdFeature.selectDogName);
    public ageInWeeks = this.store.selectSignal(selectAgeInWeeks);

    public goToFoodCalculator(): void {
        this.onCloseClick();
        this.store.dispatch(routeTo({ commands: ['main', 'nourish', 'food-portion-calculator'] }));
    }
}
