export interface HeaderButton {
    analyticsIdentifier: string;
    background: string;
    iconColour?: 'white' | 'black';
}

export interface RightHeaderButton extends HeaderButton {
    iconName: string;
    sprite: string;
    isDisabled?: boolean;
}
