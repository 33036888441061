<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [isDisabled]="isLoading()"
            [progressRatio]="progress()"
            [showBackButton]="!!previousOnboardingPage()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true" [scrollY]="false">
    <main class="question-age page-content ion-justify-content-start">
        <section class="question-age__container">
            <h1>How old is {{ dogName() }}?</h1>
        </section>

        <form id="ageForm" [formGroup]="ageForm" (ngSubmit)="onSubmit()">
            <app-radio-with-logic
                [buttons]="AGE_RADIO_OPTIONS"
                [isDisabled]="isLoading()"
                (changeEvent)="setAnswer($event)"
            />

            @if (radioResult?.complete && radioResult?.selected !== OVER_TWO_YEARS_RADIO_OPTION) {
            <section class="question-age__date-input-container">
                <p class="question-age__date-text ion-text-center s">
                    Please specify {{ dogName() }}'s {{ radioResult?.selected === ZERO_TO_FOUR_MONTHS_RADIO_OPTION ?
                    'birthday' : 'birth month' }}, or your closest estimate to help us tailor the program
                </p>

                <app-datetime
                    formControlName="dateOfBirth"
                    [id]="'datetime'"
                    [presentation]="radioResult?.selected?.domElement?.type"
                    [currentDate]="currentDate()"
                    [isLoading]="isLoading()"
                    [defaultValue]="getMaxValue(radioResult)"
                    [minDate]="getMinValue(radioResult)"
                    [maxDate]="getMaxValue(radioResult)"
                />

                @if (ageForm.dirty && ageForm.valid && radioResult?.complete && radioResult?.selected !==
                OVER_TWO_YEARS_RADIO_OPTION) {
                <p class="question-age__age-text ion-text-center s" data-test="question-age-age-text">
                    {{ dogName() }} is {{ ageForm.controls.dateOfBirth.value | age : currentDate() }} old
                </p>
                }
            </section>
            }
        </form>
    </main>
</ion-content>

<ion-footer class="question-age-footer ion-text-center page-footer">
    <ion-button
        class="ion-button-color-max"
        [ngClass]="{'question-age-footer__button--hidden' : !radioResult?.selected}"
        appTrackClick
        identifier="question-age-next-btn"
        expand="block"
        form="ageForm"
        type="submit"
        data-test="question-age-next-btn"
        [disabled]="isLoading() || ageForm.invalid"
    >
        @if (isLoading()) {
        <ion-spinner name="crescent" />
        } @else { Next }
    </ion-button>
</ion-footer>
