import { Color } from '@shared/utils/typescript';

export interface SeasonalTopicsState {
    seasonalTopics: SeasonalTopic[];
}

export interface SeasonalTopic {
    id: string;
    title: string;
    image: string;
    backgroundColour: Color;
    topic: string;
    startDate: string;
    endDate: string;
}
