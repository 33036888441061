<ion-header class="breed-selector-modal__ion-header page-header ion-no-border">
    <ion-toolbar>
        <app-header
            class="breed-selector-modal__app-header"
            title="Search breed"
            [titleColour]="'var(--ion-color-snowy)'"
            [leftButton]="leftButtonConfig"
            [rightButton]="false"
            (leftButtonPressed)="back()"
        />
    </ion-toolbar>
</ion-header>

<app-search-bar
    class="breed-selector-modal__searchbar"
    data-test="breed-selector-modal-search-bar"
    [placeholder]="'Search breed'"
    [resultListTemplate]="resultListTemplate"
    [noResultTemplate]="noResults"
    [instructionTemplate]="resultListTemplate"
    [initialFocus]="true"
    [hasResults]="results.length > 0"
    (search)="searchItems($event)"
/>

<ng-template #resultListTemplate>
    <ion-chip class="breed-selector-modal__chip">
        If your pup is a mixed breed, select the most prominent breed.
    </ion-chip>

    <ion-content class="breed-selector-modal__content">
        <ion-list>
            @for (result of results; track result.id) {
                <ion-item
                    class="breed-selector-modal__item"
                    data-test="breed-search-result"
                    (click)="selectBreed(result)"
                >
                    <ion-label class="ion-text-wrap">
                        <h3 class="breed-selector-modal__label-text">
                            {{ result.name }}
                        </h3>
                    </ion-label>
                </ion-item>
            }
        </ion-list>
    </ion-content>
</ng-template>

<ng-template #noResults let-searchValue="searchValue">
    <section class="breed-selector-modal__no-results center">
        <div class="breed-selector-modal__no-results-image">
            <img src="assets/images/oliver/oliver-looks-confused.png" />
        </div>
        <h3>No results for "{{ searchValue }}"</h3>
        <p class="breed-selector-modal__no-results-text">Try again using different spelling or keywords.</p>
    </section>
</ng-template>
