import { createAction, props } from '@ngrx/store';
import { DISMISSIBLE_INFO_ACTION_SOURCE } from './dismissible-info.constants';

export const dismissInfo = createAction(
    `${DISMISSIBLE_INFO_ACTION_SOURCE} Dismiss`,
    props<{
        id: string;
        dontShowAgain?: boolean;
    }>(),
);

export const dismissInfoWithTimestamp = createAction(
    `${DISMISSIBLE_INFO_ACTION_SOURCE} Dismiss with Timestamp`,
    props<{
        id: string;
        timestamp: string;
        dontShowAgain?: boolean;
    }>(),
);
