import { createAction, props } from '@ngrx/store';
import { NourishProductId, NourishTileId } from '../constants/nourish.constants';

export const nourishCalculateFoodButtonClicked = createAction('[Nourish] Calculate Food Button Clicked');

export const nourishTileClicked = createAction('[Nourish] Tile Clicked', props<{ id: NourishTileId }>());

export const nourishProductTileClicked = createAction(
    '[Nourish] Product Tile Clicked',
    props<{
        id: NourishProductId;
        correlationId?: 'page' | 'modal';
    }>(),
);

export const nourishProductCtaClicked = createAction(
    '[Nourish] Product CTA Clicked',
    props<{
        id: NourishProductId;
        url: string;
    }>(),
);

export const nourishSuggestedAiChatQuestionClicked = createAction(
    '[Nourish] Suggested Ai Chat Question Clicked',
    props<{ optionId: string; card: string; option: string }>(),
);
