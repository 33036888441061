import { ChatTileTopic, chatTileTopicsFeature } from '@frontend/data-access/contentful';
import { createSelector } from '@ngrx/store';
import { FEEDING_AND_CHEWING_CHAT_TILE_CONTENTFUL_IDS } from '@shared/constants';
import { Color } from '@shared/utils/typescript';
import { selectContentUnlocked, selectIsBasicPlan } from '../../../store/payment/store/payment.selectors';
import { TodayGenericCardSquare } from '../../../today/today.model';
import { NOURISH_INSIGHTS_TILES, NourishTileId } from '../../constants/nourish.constants';
import { selectIsNutritionReferral } from '@frontend/data-access/user/config-cat';
import { NourishTile } from '../../models/nourish.model';

export const selectNourishPageChatTile = createSelector(
    chatTileTopicsFeature.selectChatTileTopics,
    (chatTileTopics): ChatTileTopic | undefined => {
        return chatTileTopics.find((chatTileTopic) =>
            FEEDING_AND_CHEWING_CHAT_TILE_CONTENTFUL_IDS.includes(chatTileTopic.id),
        );
    },
);

export const selectNourishPageGenericCards = createSelector(
    selectContentUnlocked,
    selectIsBasicPlan,
    selectIsNutritionReferral,
    (contentUnlocked, isBasicPlan, isNutritionalReferral): TodayGenericCardSquare[] => {
        const nutritionalConsultationTile = {
            imageUrl: '/assets/images/bailey/bailey-holds-phone-in-mouth.svg',
            title: 'Prevent nutritional mistakes',
            backgroundColor: Color.Lola,
            cardName: NourishTileId.NUTRITIONAL_CONSULTATION,
            contentUnlocked: !isBasicPlan,
            isBasicPlan,
        };

        const trainingCoachTile = {
            imageUrl: '/assets/images/trainers/coach-petrina-red-circle.png',
            title: 'Training coach',
            description: 'Replies within 1 hour',
            backgroundColor: Color.Harley,
            cardName: 'puppy-coach',
            contentUnlocked: contentUnlocked && !isBasicPlan,
            isBasicPlan,
        };

        const tiles: TodayGenericCardSquare[] = [trainingCoachTile];

        if (isNutritionalReferral) {
            tiles.unshift(nutritionalConsultationTile);
        }

        return tiles;
    },
);

export const selectNourishPageSupportTiles = createSelector(
    selectNourishPageGenericCards,
    selectNourishPageChatTile,
    selectIsNutritionReferral,
    (genericCards, chatTile, isNutritionalReferral): (TodayGenericCardSquare | ChatTileTopic)[] => {
        const supportTiles: (TodayGenericCardSquare | ChatTileTopic)[] = [...genericCards];

        if (chatTile) {
            if (isNutritionalReferral) {
                supportTiles.splice(1, 0, chatTile);
            } else {
                supportTiles.unshift(chatTile);
            }
        }
        return supportTiles;
    },
);

export const selectNourishPageInsightsTiles = createSelector(
    selectIsNutritionReferral,
    (isNutritionalReferral): NourishTile[] => {
        const nutritionalConsultationTile = {
            id: NourishTileId.NUTRITIONAL_CONSULTATION,
            title: 'Prevent nutritional mistakes',
            pill: 'Nutrition',
            colour: Color.Harley,
            image: {
                src: '/assets/images/bailey/bailey-holds-phone-in-mouth.svg',
                alt: 'Bailey holding a telephone in his mouth by the cable',
            },
        };

        const tiles: NourishTile[] = NOURISH_INSIGHTS_TILES;

        if (isNutritionalReferral) {
            tiles.unshift(nutritionalConsultationTile);
        }

        return tiles;
    },
);
