import { LoadingState } from '@frontend/data-access/shared-models';
import { CourseType } from '@shared/content-api-interface';
import { Color } from '@shared/utils/typescript';
import { RichTextContent } from 'contentful';
import { CourseStep } from './step.model';

export interface CourseState {
    courses: Course[];
    loading: LoadingState;
}

export interface Course {
    title: string;
    subTitle?: string;
    id: string;
    type: CourseType;
    stepIds: string[];
    steps: CourseStep[]; // remove
    rank: number;
    splits: number[];
    introduction: string;
    explanation: RichTextContent;
    color: Color;
    imageUrl: string;
    courseCardSquiggle: string;
}
