import { Color } from '@shared/utils/typescript';
import { NourishProductTile, NourishTile } from '../models/nourish.model';

// TODO: NBSon - evaluate ALL images in the app and ensure they are optimised, cropped and being used correctly
// we may want to unify these images somehow, e.g. have a file with the filename (src)/alt and unify language - may want to check with Marketing about alts
// we can also for the pups give them identities/personalities and use them consistently across the app

export enum NourishTileId {
    NUTRITIONAL_CONSULTATION = 'nutritional-consultation',
    WHAT_CAN_POOP_TELL_YOU = 'what-can-poop-tell-you',
    PREVENT_DIARRHOEA = 'prevent-diarrhoea',
    RIGHT_FOOD_CHOICE = 'right-food-choice',
    HOW_MUCH_EXERCISE = 'how-much-exercise',
    PUP_NOT_EATING = 'pup-not-eating',
    TUMMY_TROUBLES = 'tummy-troubles',
    WEIGH_YOUR_DOG = 'weigh-your-dog',
    SWITCH_FOOD = 'switch-food',
    CALCULATE_FOOD = 'calculate-food',
}

export const NOURISH_TAB_INDICATOR_ID = 'nourish-tab-indicator';

export const NOURISH_INSIGHTS_TILES: NourishTile[] = [
    {
        id: NourishTileId.WHAT_CAN_POOP_TELL_YOU,
        title: 'What can poop tell you?',
        pill: 'Tips',
        colour: Color.Max,
        image: {
            src: '/assets/images/illustrations/nourish/dog-poop-inside-red-slipper.svg',
            alt: 'A dog poop inside a red slipper',
        },
    },
    {
        id: NourishTileId.PREVENT_DIARRHOEA,
        title: 'How to prevent diarrhoea?',
        pill: 'Digestion',
        colour: Color.Alvin,
        image: {
            src: '/assets/images/buddy/buddy-diarrhoea.svg',
            alt: 'Buddy looks sad sitting next to diarrhoea',
        },
    },
    {
        id: NourishTileId.RIGHT_FOOD_CHOICE,
        title: 'Making the right food choice',
        pill: 'Nutrition',
        colour: Color.Harley,
        image: {
            src: '/assets/images/buddy/buddy-licks-food-from-treat-mat.svg',
            alt: 'Buddy licking food from a treat mat',
        },
    },
    {
        id: NourishTileId.HOW_MUCH_EXERCISE,
        title: 'How much exercise is enough?',
        pill: 'Exercise',
        colour: Color.Buddy,
        image: {
            src: '/assets/images/charly/charly-runs-with-snake-toy-cropped.svg',
            alt: 'Charly running playfully with a toy snake in his mouth',
        },
    },
    {
        id: NourishTileId.PUP_NOT_EATING,
        title: 'Why is my pup not eating?',
        pill: 'Feeding',
        colour: Color.Alvin,
        image: {
            src: '/assets/images/charly/charly-not-eating.svg',
            alt: 'Charly lays down looking sad next to his food bowl',
        },
    },
    {
        id: NourishTileId.TUMMY_TROUBLES,
        title: 'Help with tummy troubles',
        pill: 'Digestion',
        colour: Color.Alvin,
        image: {
            src: '/assets/images/charly/charly-looks-at-poop.svg',
            alt: 'Charly sitting and looking at his poop with surprise',
        },
    },
    {
        id: NourishTileId.WEIGH_YOUR_DOG,
        title: 'How to weigh your dog?',
        pill: 'Tips',
        colour: Color.Max,
        image: {
            src: '/assets/images/bailey/bailey-on-scales.svg',
            alt: 'Bailey sits on weighing scales',
        },
    },
    {
        id: NourishTileId.SWITCH_FOOD,
        title: 'When and how to switch food',
        pill: 'Nutrition',
        colour: Color.Harley,
        image: {
            src: '/assets/images/oliver/oliver-drooling-food.svg',
            alt: 'Oliver drools next to a bag of dog food',
        },
    },
];

export enum NourishProductId {
    LILYS_KITCHEN = 'lilys-kitchen',
    TAILS = 'tails',
    PURINA_PRO_PLAN = 'purina-pro-plan',
}

export const NOURISH_CALCULATE_FOOD_TASK_TILE: NourishTile = {
    id: NourishTileId.CALCULATE_FOOD,
    title: "Calculate your pup's food portion",
    pill: 'Task',
    colour: Color.Max,
    image: {
        src: 'assets/images/illustrations/food-calculator/food-bowl.png',
        alt: 'An illustration of a dog bowl with food in it',
    },
};

export const NOURISH_LILY_KITCHEN_PRODUCT_TILE: NourishProductTile = {
    id: NourishProductId.LILYS_KITCHEN,
    title: 'Natural ingredients',
    colour: Color.Alvin,
    image: {
        src: '/assets/images/third-party/lilys-kitchen/lilys-kitchen-logo-light.svg',
        alt: 'An image depicting the Lily\'s Kitchen logo with the tagline "Proper food for pets"',
    },
    chip: '30% OFF',
};

export const NOURISH_TAILS_PRODUCT_TILE: NourishProductTile = {
    id: NourishProductId.TAILS,
    title: '100% tailored to [NAME]',
    colour: Color.Harley,
    image: {
        src: '/assets/images/third-party/tails/tails-logo-light.svg',
        alt: 'An image depicting the Tails.com logo',
    },
    chip: '75% OFF first box',
};

export const NOURISH_PURINA_PRO_PLAN_PRODUCT_TILE: NourishProductTile = {
    id: NourishProductId.PURINA_PRO_PLAN,
    title: 'Advanced nutrition',
    colour: Color.Jack,
    image: {
        src: '/assets/images/third-party/purina/purina-pro-plan-logo.svg',
        alt: 'An image depicting the Purina Pro Plan logo',
    },
    chip: 'FREE probiotics',
};

export const DEFAULT_NOURISH_PRODUCT_TILES: NourishProductTile[] = [
    NOURISH_TAILS_PRODUCT_TILE,
    NOURISH_PURINA_PRO_PLAN_PRODUCT_TILE,
    NOURISH_LILY_KITCHEN_PRODUCT_TILE,
];

export const LILYS_KITCHEN_ADULT_CTA_LINK =
    'https://www.lilyskitchen.co.uk/for-dogs/adult?dm_t=0%2C0%2C0%2C0%2C0&utm_campaign=crm_zigzag_30FD&utm_medium=app&utm_source=lilys_kitchen';
export const LILYS_KITCHEN_PUPPY_CTA_LINK =
    'https://www.lilyskitchen.co.uk/for-dogs/puppy/?utm_campaign=crm_zigzag_30FD&utm_medium=app&utm_source=lilys_kitchen&dm_t=0,0,0,0,0';

export const TAILS_ADULT_CTA_LINK =
    'https://tails.com/gb/lp/multi7525/?pc=ZIGZAG1&utm_medium=partnership&utm_source=zigzagadult&utm_campaign=75-25&utm_term=closedgroup&utm_content=incentive';
export const TAILS_PUPPY_CTA_LINK =
    'https://tails.com/gb/lp/multi7525/?pc=ZIGZAG75&utm_medium=partnership&utm_source=zigzagpuppy&utm_campaign=75-25&utm_term=closedgroup&utm_content=incentive';

export const PURINA_PRO_PLAN_CTA_LINK =
    'https://shop.purina.co.uk/referrals/zigzag.list?utm_source=referral&utm_medium=zigzag&utm_campaign=email';
