<ion-content class="page-ion-content">
    <ion-header>
        <ion-toolbar class="nourish-page__toolbar" [color]="Color.Bailey" />
    </ion-header>
    <app-nourish-header />
    <main class="nourish-page__content">
        <section>
            <h3 class="no-margin-top">Your insights</h3>
            <app-slider class="nourish-page__insights-slider" [gap]="12" [lastItemPadding]="20">
                @for (tile of insightsTiles(); track tile.title) {
                <app-nourish-tile
                    [id]="tile.id"
                    [title]="tile.title"
                    [pill]="tile.pill"
                    [image]="tile.image"
                    [colour]="tile.colour"
                />
                }
            </app-slider>
        </section>

        <app-nourish-product-tiles [productTiles]="productTiles" />

        <section>
            <h3 class="no-margin">Find answers for growing pups</h3>
            <app-slider [gap]="20" [lastItemPadding]="20">
                @for (tile of supportTiles(); track tile.title) {
                <app-slider-item>
                    @if (isTodayGenericCardSquare(tile)) {
                    <app-generic-card-square
                        class="nourish-page__support-tile"
                        appTrackClick
                        [identifier]="tile.contentUnlocked ? 'nourish-page-' + tile.cardName + '-tile': 'nourish-page-' + tile.cardName + '-tile-locked'"
                        [title]="tile.title"
                        [description]="tile.description"
                        [imageUrl]="tile.imageUrl"
                        [background]="tile.backgroundColor"
                        [unlocked]="tile.contentUnlocked"
                        [isBasicPlan]="isBasicPlan()"
                        (click)="onCardClick(tile.contentUnlocked, tile.cardName)"
                    />
                    } @if (isChatTileTopic(tile)) {
                    <app-option-selector-square
                        #chatTile
                        class="nourish-page__chat-tile"
                        [title]="tile.title"
                        [background]="tile.colour"
                        [imageUrl]="tile.image"
                        [options]="tile.chatTileContent"
                        (selectedChange)="onChatTileTopicClick($event)"
                    />
                    }
                </app-slider-item>
                }
            </app-slider>
        </section>
    </main>
</ion-content>
