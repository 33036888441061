<app-header
    class="app-header--modal-small"
    [leftButton]="false"
    [rightButton]="{
        analyticsIdentifier: 'training-reminder-modal-close-btn',
        sprite: 'light',
        iconName: 'xmark',
        background: 'none',
        iconColour: 'black',
    }"
    (rightButtonPressed)="onClickCloseModal()"
/>

<ion-content class="training-reminder-modal" [fullscreen]="true">
    <h2 class="training-reminder-modal__title unset-margin">
        With training reminders you are <span class="highlight">75%</span> more likely to achieve your goals.
    </h2>

    <form class="training-reminder-modal-datetime" [formGroup]="form">
        <p class="training-reminder-modal-datetime__title unset-margin">Select your best time to train</p>
        <ion-datetime
            class="training-reminder-modal-datetime__ion-datetime"
            formControlName="time"
            presentation="time"
            hourCycle="h12"
            size="cover"
        />
    </form>
    <p class="training-reminder-modal-datetime__title unset-margin">Choose the days for your training reminders</p>
    <div class="training-reminder-modal__day-buttons">
        @for (day of days; let index = $index; track index) {
            <button [ngClass]="{ selected: day.selected }" (click)="toggleDay(day)">{{ day.text }}</button>
        }
    </div>

    <ion-footer class="training-reminder-modal-footer page-footer">
        <ion-button
            color="max"
            expand="block"
            data-test="training-reminder-modal-set-reminder-btn"
            (click)="onClickSetReminder()"
        >
            Set reminder
        </ion-button>
    </ion-footer>
</ion-content>
