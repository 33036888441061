import { Component, EventEmitter, HostBinding, input, Input, Output } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ElementRerenderDirective, IconComponent } from '@frontend/ui';
import { IonButton, IonButtons, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { HeaderButton, RightHeaderButton } from './header.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonButtons,
        IonTitle,
        IonToolbar,
        IconComponent,
        ElementRerenderDirective,
    ],
})
export class HeaderComponent {
    @HostBinding('style.--toolbar-background')
    @Input()
    background = 'none';

    @Input() title = '';
    @Input() rightImage: { content: string } | false = false;
    @Input({ required: true }) leftButton!: HeaderButton | false;
    @Input({ required: true }) rightButton!: RightHeaderButton | false;

    @Output() leftButtonPressed = new EventEmitter();
    @Output() rightButtonPressed = new EventEmitter();

    public titleColour = input('');
    /** Whether ALL header buttons should be disabled. **/
    public isDisabled = input(false);

    public rightButtonAction(): void {
        if (this.rightButton === false || this.rightButton.isDisabled || this.isDisabled()) {
            return;
        }

        this.rightButtonPressed.emit();
    }

    public leftButtonAction(): void {
        if (this.leftButton === false || this.isDisabled()) {
            return;
        }

        this.leftButtonPressed.emit();
    }
}
