<ion-content [color]="Color.Harley" class="nutrition-modal">
    <button
        (click)="onCloseClick()"
        appTrackClick
        class="xl nutrition-modal__close-button"
        identifier="nourish-nutrition-modal-close-btn"
    >
        <i class="fa-light fa-xmark"></i>
    </button>

    <article class="nutrition-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2 class="no-margin-bottom">
            Book a nutritional consultation to receive a personalised nutrition plan and avoid the pitfalls of improper
            nutrition
        </h2>

        <img
            alt="Buddy nibbling on a phone wire"
            class="nutrition-modal__image"
            src="/assets/images/charly/charly-nibbles-phone-wire.svg"
        />

        <section class="nutrition-modal__nutrition-benefits">
            <div class="nutrition-modal__benefit">
                <i class="fa-regular fa-lg fa-arrow-up-right-dots nutrition-modal__icon"></i>
                <p class="s no-margin">Avoid growth disorder</p>
            </div>

            <div class="nutrition-modal__benefit">
                <i class="fa-regular fa-lg fa-dog nutrition-modal__icon"></i>
                <p class="s no-margin">Prevent coat and skin issues</p>
            </div>

            <div class="nutrition-modal__benefit">
                <i class="fa-regular fa-lg fa-memo-circle-check nutrition-modal__icon"></i>
                <p class="s no-margin">Solve digestive issues & soft poos with tailored nutrition</p>
            </div>
        </section>

        <ul [ngClass]="Color.AppOutline" class="nutrition-modal__nutrition-steps no-margin">
            <li>
                <h3 class="no-margin-top">
                    <span class="nutrition-modal__list-number">①</span> Chat with a nutritionist
                </h3>
                <p class="s">
                    Schedule a call with a nutrition expert to discuss
                    {{ '[NAME_C]' | textReplacement }}’s needs, at your convenience.
                </p>
            </li>

            <hr class="nutrition-modal__nutrition-steps-break" />

            <li>
                <h3><span class="nutrition-modal__list-number">②</span> Get your nutrition plan</h3>
                <p class="s">
                    Receive a <b class="s">tailored nutrition plan</b> straight to your inbox. Simple, convenient, and
                    100% free.
                </p>
            </li>

            <hr class="nutrition-modal__nutrition-steps-break" />

            <li>
                <h3><span class="nutrition-modal__list-number">③</span> Personalised nutrition, delivered</h3>
                <p class="s no-margin-bottom">
                    Get monthly deliveries of <b class="s">nutritionally tailored meals</b> with a
                    <b class="s">portion-perfect scoop</b> to make feeding simple.
                </p>
            </li>
        </ul>

        <img
            alt="Powered by Purina logo"
            class="nutrition-modal__purina-image"
            src="/assets/images/third-party/powered-by-purina-white.svg"
        />
    </article>

    <ion-footer class="nutrition-modal__footer ion-text-center gradient-footer">
        <ion-button
            appTrackClick
            color="max"
            expand="full"
            shape="round"
            data-test="nutrition-modal-book-consultation-btn"
            [identifier]="unlocked() ? 'nutrition-modal-book-consultation-btn' : 'nutrition-modal-start-free-trial-btn'"
            (click)="unlocked() ? bookConsultation() : openPaymentModal()"
        >
            @if (unlocked()) {
                Book my consultation
            } @else if (!hasHistoricalPurchase()) {
                Start my free trial
            } @else {
                See plans
            }
        </ion-button>
    </ion-footer>
</ion-content>
